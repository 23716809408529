<template>

  <div id="container" style="height: 160vw;width: 100%"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "Map",
  methods: {
    initMap: function () {
      AMapLoader.load({
        "key": "f7c8f1754dec8e4a9b202b27f97d133a",                                          // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "1.4.15",                                // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.MapType',
          'AMap.Geolocation',
        ],                                      // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        "AMapUI": {                                         // 是否加载 AMapUI，缺省不加载
          "version": '1.1',                               // AMapUI 缺省 1.1
          "plugins":[],                                   // 需要加载的 AMapUI ui插件
        },
        "Loca":{                                            // 是否加载 Loca， 缺省不加载
          "version": '1.3.2'                              // Loca 版本，缺省 1.3.2
        },
      }).then((AMap)=>{
        var map = new AMap.Map('container', {
          zoom:11,                                        //级别
          center: [120.26458,30.200097]              //中心点坐标
        });
        map.addControl(new AMap.ToolBar());
        // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
        map.addControl(new AMap.Scale());
        // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
        map.addControl(new AMap.Geolocation());

        var marker = new AMap.Marker({
          position:[120.26458,30.200097]   //位置
        })
        map.add(marker);//添加到地图
      }).catch(e => {
        console.log(e);
      })
    }
  },
  created: function () {
    var me= this;
    me.initMap();
  }
}
</script>

<style scoped>

</style>